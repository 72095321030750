import React from 'react';
import Zoro from '../assets/code-connections.jpg';
import Luffy from '../assets/orange.png';
import Sanji from '../assets/spanish-cards.png';
import Chopper from '../assets/trending-news.png';

const Portfolio = () => {

    const portfolios = [
        {
            id: 1,
            src: Zoro,
            link: "https://github.com/erikcorea/fe-final-project",
            github: "https://github.com/erikcorea/fe-final-project",
        },
        {
            id: 2,
            src: Luffy,
            link: "http://team-orange4.herokuapp.com/",
            github: "https://github.com/Team-Orange4/orange-frontend",
        },
        {
            id: 3,
            src: Sanji,
            link: "https://estudiarcards.netlify.app/",
            github: "https://github.com/erikcorea/flash-card",
        },
        {
            id: 4,
            src: Chopper
        },
    ];

    return (
        <div
          name="portfolio"
          className="bg-gradient-to-b from-black to-gray-800 w-full text-white md:h-screen"
        >
          <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
            <div className="pb-8">
              <p className="text-4xl font-bold inline border-b-4 border-gray-500">
                Portfolio
              </p>
              <p className="py-6">Check out some of my work right here</p>
            </div>
    
            <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-12 sm:px-0">
              {portfolios.map(({ id, src, link, github }) => (
                <div key={id} className="shadow-md shadow-gray-600 rounded-lg">
                  <img
                    src={src}
                    alt=""
                    className="rounded-md duration-200 hover:scale-105"
                  />
                  <div className="flex items-center justify-center">
                    <a href={link}><button className="w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105">Demo</button></a>
                    <a href={github}><button className="w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105">Code</button></a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    };
    
    export default Portfolio;